import React from "react";

function FeatureSection() {
  return (
    <section className="feature-area-s2 s-padding">
      <div className="container">
        <div className="s-title-wrap">
          <span className="s-sub-title">Tận dụng những nền tảng sẵn có</span>
          <h2 className="s-title">+với quy trình phát triển cơ bản</h2>
        </div>
        <div className="row feature-area-inner">
          <div className="feature-s4">
            <div className="badge">01</div>
            <div className="content">
              <h3>Tư vấn</h3>
              <p>
                Dựa trên những thông tin mà bạn cung cấp, chúng tôi sẽ đưa ra
                những tư vấn phần mềm phù hợp nhất.
              </p>
            </div>
          </div>
          <div className="feature-s4">
            <div className="badge">02</div>
            <div className="content">
              <h3>Xây dựng</h3>
              <p>
                Đội ngũ thiết kế & lập trình viên của chúng tôi sẽ giúp bạn xây
                dựng phần mềm theo yêu cầu.
              </p>
            </div>
          </div>
          <div className="feature-s4 third">
            <div className="badge">03</div>
            <div className="content">
              <h3>Triển khai</h3>
              <p>
                Hỗ trợ triển khai dự án theo giai đoạn thoả thuận. Hỗ trợ kỹ
                thuật xuyên suốt.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
