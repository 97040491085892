import React from "react";
import { getEnv } from "../../../../configs";

function PricingSection() {
  return (
    <section className="pricing-section-s1 s-padding">
      <div className="container">
        <div className="s-title-wrap">
          <span className="s-sub-title">Dịch vụ trọn gói</span>
          <h2 className="s-title">Cho doanh nghiệp vừa và nhỏ</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="pricing-t-s1 bx-1">
              <div className="pt-header">
                <div className="icon">
                  <img
                    src={"/assets/images/feature-icons/development-icon.svg"}
                    alt=""
                  />
                </div>
                <div className="price">
                  <span className="p-pre">vnđ</span>
                  <h3>1.5tr</h3>
                  <span className="p-suf">/hàng năm</span>
                </div>
                <div className="pt-ribbon">STARTUP</div>
              </div>
              <div className="pt-body">
                <ul className="pt-features">
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Chưa bao gồm thiết kế web</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">1 tên miền .com</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Chứng chỉ SSL Let's Encrypt</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Hosting dung lượng 1GB</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Hỗ trợ kỹ thuật trong tuần</span>
                  </li>
                </ul>
              </div>
              {/* <div className="pt-footer">
                <a href="/pricing" className="pt-btn btn btn-s1">
                  Buy Now
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="pricing-t-s1 bx-2 floating">
              <div className="pt-header">
                <div className="icon">
                  <img
                    src={"/assets/images/feature-icons/development-icon.svg"}
                    alt=""
                  />
                </div>
                <div className="price">
                  <span className="p-pre">vnđ</span>
                  <h3>2.5tr</h3>
                  <span className="p-suf">/hàng năm</span>
                </div>
                <div className="pt-ribbon">BUSINESS</div>
              </div>
              <div className="pt-body">
                <ul className="pt-features">
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">+STARTUP</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">+1GB dung lượng hosting</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Băng thông không giới hạn</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Backup dữ liệu hàng tuần</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Hỗ trợ 24/7</span>
                  </li>
                </ul>
              </div>
              {/* <div className="pt-footer">
                <a href="/pricing" className="pt-btn btn btn-s1">
                  Buy Now
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="pricing-t-s1 bx-3">
              <div className="pt-header">
                {/* <div className="icon">
                  <img
                    src={"/assets/images/feature-icons/development-icon.svg"}
                    alt=""
                  />
                </div> */}
                <div className="pt-footer">
                <a href={getEnv("COMPANY_TELEGRAM")} className="pt-btn btn btn-s1" target="_blank">
                 Liên hệ trực tiếp
                </a>
              </div>
                <div className="pt-ribbon">CUSTOMIZE</div>
              </div>
              <div className="pt-body">
                <ul className="pt-features">
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Trao đổi, lấy yêu cầu trực tiếp</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Mô tả nghiệp vụ trên SRS và wireframe</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Thiết kế giao diện trên Figma</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Lập trình ứng dụng chuyên sâu</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Nhiều môi trường phát triển</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={"/assets/images/feature-icons/check-price.svg"}
                        alt=""
                      />
                    </span>
                    <span className="text">Hỗ trợ bảo trì & vận hành hệ thống theo yêu cầu</span>
                  </li>
                </ul>
              </div>
              {/* <div className="pt-footer">
                <a href="/pricing" className="pt-btn btn btn-s1">
                 Liên hệ trực tiếp
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingSection;
