import Cookies from "universal-cookie";
const cookies = new Cookies();

const DefaultConfigs = {
  ENV: "local",

  // General information
  PUBLIC_URL: "http://localhost:3001",
  NUMBER_DECIMAL_DISPLAY: 8,
  DEFAULT_RECOVERY_USDT_EMAIL: "superadmin@steady",

  // RESTful APIs
  URL_API_MAIN_CLIENT_SIDE: "http://localhost:3002",
  URL_SOCKET: "",
  URL_SOCKET_APP_ID: "",
  URL_API_MAIN_STORAGE_SIDE: "https://api.steady.vn/storage",

  GOOGLE_RECAPTCHA_KEY: "6LetsigoAAAAAIl8FqwbYxOKejmObeSVythCieS8",
  TRADE_REAL_COIN_CODE: "USD",
  TRADE_DEMO_COIN_CODE: "DEMO",
  PASSWORD_SUPERADMIN: "",
  BSC_SCAN: "https://testnet.bscscan.com/tx/",
  TRON_SCAN: "https://nile.tronscan.org/#/transaction/",
  GT4_CAPTCHA_ID: "8465bfb60e8ca68cf04283922c3bff1b",

  //
  COMPANY_ADDRESS: "Quận 9, Thành phố Thủ Đức",
  COMPANY_MAP: "https://www.google.com/maps/place/T%C4%83ng+Nh%C6%A1n+Ph%C3%BA,+Ph%C6%B0%E1%BB%9Bc+Long+B,+Qu%E1%BA%ADn+9,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vietnam/@10.8313742,106.7731179,17z/data=!3m1!4b1!4m6!3m5!1s0x31752701764a4589:0x95a23cab4cbb76f0!8m2!3d10.8313742!4d106.7756928!16s%2Fg%2F1tftz0zv?entry=tts&shorturl=1",
  COMPANY_PHONE: "+84-889-950-752",
  COMPANY_EMAIL: "duynguyen609@gmail.com",
  COMPANY_FACEBOOK: "https://www.facebook.com/steady.vn",
  COMPANY_LINKEDIN: "https://www.linkedin.com/",
  WEBSITE_DOMAIN: "https://www.steady.vn/",
  WEBSITE_DOMAIN_SHORT: "www.steady.vn",
  COMPANY_TELEGRAM: "https://t.me/steadyvn"
};

export function getEnv(
  key:
    | "ENV"

    // General information
    | "PUBLIC_URL"
    | "NUMBER_DECIMAL_DISPLAY"
    | "DEFAULT_RECOVERY_USDT_EMAIL"

    // RESTful APIs
    | "URL_API_MAIN_CLIENT_SIDE"
    | "URL_SOCKET"
    | "URL_SOCKET_APP_ID"
    | "URL_API_MAIN_STORAGE_SIDE"
    | "GOOGLE_RECAPTCHA_KEY"
    | "TRADE_REAL_COIN_CODE"
    | "TRADE_DEMO_COIN_CODE"
    | "PASSWORD_SUPERADMIN"
    | "BSC_SCAN"
    | "TRON_SCAN"
    | "GT4_CAPTCHA_ID"

    //
    | "COMPANY_ADDRESS"
    | "COMPANY_MAP"
    | "COMPANY_PHONE"
    | "COMPANY_EMAIL"
    | "COMPANY_FACEBOOK"
    | "COMPANY_LINKEDIN"
    | "WEBSITE_DOMAIN"
    | "WEBSITE_DOMAIN_SHORT"
    | "COMPANY_TELEGRAM"
): string {
  return cookies.get(key) || DefaultConfigs[key];
}

export const isDev = getEnv("ENV") !== "production";

export const CollectionDisplayOptions = [
  {
    label: "Trang chủ",
    value: "home",
  },
  {
    label: "Dành cho người mới",
    value: "new-member",
  },
  {
    label: "Trang sản phẩm",
    value: "product-page",
  },
];
