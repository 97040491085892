import React, { FC } from "react";
import * as Yup from "yup";
import { InputPassword } from "../../../../components";
import { translate } from "../../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { UserService } from "../../../../services";
import { ConfigService } from "../../../../services/config/config.service";
import { withConfigWraper } from "../wraper";

const ChangePassword: FC<any> = withConfigWraper(() => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "currentPassword",
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "newPassword",
        validate: Yup.string()
          .required("Must be provided")
          .min(8, "Password is too short - should be 8 characters minimum.")
          .max(20, "Password is too long - only 20 characters maximum.")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
            "Password must contain one uppercase letter, one lowercase letter, one number and one special case character"
          ),
      },
      {
        name: "confirmNewPassword",
        validate: Yup.string()
          .required("Must be provided")
          .test({
            message: "Password confirmation does not match",
            test: function (value) {
              const password = this.resolve(Yup.ref("newPassword"));
              if (password && value && value !== password) return false;
              return true;
            },
          })
          .min(8, "Password is too short - should be 8 characters minimum.")
          .max(20, "Password is too long - only 20 characters maximum.")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
            "Password must contain one uppercase letter, one lowercase letter, one number and one special case character"
          ),
      },
    ],
    onSubmit: async (values) => {
      let payload = {
        oldPassword: values?.currentPassword,
        newPassword: values?.confirmNewPassword,
      };
      ConfigService.ChangePassword(payload)
        .then((res) => {
          CreateAlert({ type: "success", message: "Your password has changed" });
          UserService.logout();
        })
        .catch((err) => {
          CreateAlert({ type: "danger", message: translate(err.message) });
        })
        .finally(() => {});
    },
  });

  return (
    <div className="row">
      <div className="col-sm-6">
        <h1>Change Password</h1>
        <div className="row">
          <div className="col-8 ">
            <InputWraper
              label={"Current password"}
              // placeholder={translate('Enter current password')}
              inputProps={getInputProps("currentPassword")}
              component={InputPassword}
            />
            <InputWraper
              label={"New password"}
              // placeholder={translate("Enter new password")}
              inputProps={getInputProps("newPassword")}
              component={InputPassword}
            />
            <InputWraper
              label={"Confirm password"}
              // placeholder={translate('Enter confirm password')}
              inputProps={getInputProps("confirmNewPassword")}
              component={InputPassword}
            />
          </div>
        </div>
        <Button
          label={"Change Password"}
          type="submit"
          buttonType="success"
          isLoading={isSubmitting}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
});

export default ChangePassword;
