import React, { FC } from "react";
import TitleSection from "../common/TitleSection";
import Footer from "../HomeOne/Footer";
import MainSection from "./MainSection";
import InnerPageWrapper from "../common/InnerPageWrapper";

export const ServiceDetailsPage: FC = () => {
  return (
    <InnerPageWrapper>
      <TitleSection
        title="service details"
        paths={[
          { name: "home", path: "/" },
          { name: "service details", path: "/service-details" },
        ]}
      />
      <MainSection />
      <Footer />
    </InnerPageWrapper>
  );
};
