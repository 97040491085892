import React, { FC } from "react";
import "react-calendar/dist/Calendar.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./main.scss";

import { PageAuthLogin } from "./pages/auth";
import Activities from "./pages/admin/config/activities";
import ChangePassword from "./pages/admin/config/change-password";
import { MaintenanceConfig } from "./pages/admin/config/maintenance/maintenanceConfig";
import RolePermission from "./pages/admin/config/role-permission";
import { WithdrawConfig } from "./pages/admin/config/withdraw/withdrawConfig";
import { TransactionDemoList } from "./pages/admin/transaction/transaction/TransactionDemoList";
import { TransactionList } from "./pages/admin/transaction/transaction/TransactionList";
import { UserList } from "./pages/admin/user";
import { UserDetail } from "./pages/admin/user/user/UserDetail";
import { IPageProps, withPageWraper } from "./pages/wraper";
import NewsPage from "./pages/admin/config/news";
import { CreateNewsPage } from "./pages/admin/config/news/createNews";
import { EditNewsPage } from "./pages/admin/config/news/editNews";

/////////////////// Steady Client ///////////////////
import { AboutPage } from "./pages/homepage/components/About";
import { ServicePage } from "./pages/homepage/components/Service";
import { HomePage } from "./pages/homepage/components/HomeOne";
import { ContactPage } from "./pages/homepage/components/Contact";
import { ServiceDetailsPage } from "./pages/homepage/components/ServiceDetails";
import { PaymentInfoPage } from "./pages/homepage/components/PaymentInfo";
/////////////////////////////////////////////////////////

/////////////////// Steady Admin ///////////////////
import { GitlabVariables } from "./pages/admin/swatteam/gitlab-variables";
/////////////////////////////////////////////////////////

export const Routes = {
  userList: {
    path: "/admin/user-list",
    component: UserList,
  },
  userDetail: {
    path: "/admin//user-list/:email",
    component: UserDetail,
    renderPath: (email: string) => `/admin/user-list/${email}`,
  },
  transactionList: {
    path: "/admin/transaction-list",
    component: TransactionList,
  },
  transactionDemoList: {
    path: "/admin/transactions-demo",
    component: TransactionDemoList,
  },
  withdrawConfig: {
    path: "/admin/server-config-withdraw",
    component: WithdrawConfig,
  },
  maintenanceConfig: {
    path: "/admin/server-config-maintenance",
    component: MaintenanceConfig,
  },
  rolePermission: {
    path: "/admin/server-config-role-permission",
    component: RolePermission,
  },
  changePassword: {
    path: "/server-config-change-password",
    component: ChangePassword,
  },
  activities: {
    path: "/admin/server-config-activities",
    component: Activities,
  },
  News: {
    path: "/admin/server-config/news",
    component: NewsPage,
  },
  CreateNews: {
    path: "/admin/server-config/news/create",
    component: CreateNewsPage,
  },
  EditNews: {
    path: "/admin/server-config/news/edit",
    component: EditNewsPage,
  },

  /////////////////// Steady Admin ///////////////////
  GitlabVariables: {
    path: "/admin/swat-team/gitlab-variables",
    component: GitlabVariables,
  },
  /////////////////////////////////////////////////////////
};

export const AppRoutes: FC = () => {
  return (
    <Router>
      <Switch>
        {Object.values(Routes).map((item: any, key) => {
          return (
            <Route
              key={key}
              exact
              path={item.path}
              component={withPageWraper((props: IPageProps) => {
                return <item.component {...props} />;
              })}
            />
          );
        })}

        <Route path="/authentication/login" component={PageAuthLogin} />

        <Route path="/about" component={AboutPage} />
        <Route path="/service" component={ServicePage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/service-detail" component={ServiceDetailsPage} />
        <Route path="/payment-info" component={PaymentInfoPage} />

        <Route path="/" component={HomePage} />
      </Switch>
    </Router>
  );
};
