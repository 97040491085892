import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Drawer({ type = 0 }) {
  const [drawer, setDrawer] = useState(false);
  const drawerHandler = () => {
    setDrawer(!drawer);
  };
  useEffect(() => {
    const selectBody = document.querySelector("html");
    if (drawer) {
      selectBody.style.overflowY = "hidden";
    } else {
      selectBody.style.overflowY = "auto";
    }
  }, [drawer]);
  return (
    <>
      <header className="mobile-header">
        <div className="container">
          <div className="mobile-header__container">
            <div className="p-left">
              <div className="logo">
                <Link to="/">
                  {type === 1 ? (
                    <img
                      src={"/assets/images/logos/logo-gradient-white.svg"}
                      alt=""
                    />
                  ) : type === 2 ? (
                    <img src={"/assets/images/logos/logo-white.svg"} alt="" />
                  ) : type === 3 ? (
                    <img src={"/assets/images/logos/logo-white-2.png"} alt="" />
                  ) : (
                    <img src={"/assets/images/logos/logo-white-2.png"} alt="" />
                  )}
                </Link>
              </div>
            </div>
            <div className="p-right">
              <button type="button" onClick={drawerHandler} id="nav-opn-btn">
                <i className="fa-solid fa-bars"></i>
              </button>
            </div>
          </div>
        </div>
      </header>
      <aside id="offcanvas-nav" className={`${drawer ? "open" : ""}`}>
        <nav className="m-nav">
          <button type="button" onClick={drawerHandler} id="nav-cls-btn">
            <i className="fa-solid fa-xmark"></i>
          </button>
          <div className="logo">
            <Link to="/">
              <img src={"/assets/images/logos/logo-white-2.png"} alt="" />
            </Link>
          </div>
          <ul className="nav-links">
            <li className="dropdown">
              <Link to="/">Trang chủ</Link>
            </li>
            <li>
              <Link to="/about">Về chúng tôi</Link>
            </li>
            <li>
              <Link to="/service">Dịch vụ - Kinh doanh</Link>
            </li>
            <li>
              <Link to="/payment-info">Thông tin thanh toán</Link>
            </li>
            <li>
              <Link to="/contact">Liên hệ</Link>
            </li>
          </ul>
          {/* <ul className="social-icons-s1">
            <li>
              <Link to="https://www.facebook.com/QuomodoSoft">
                <i className="fa-brands fa-facebook-f"></i>
              </Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/QuomodoSoft">
                <i className="fa-brands fa-twitter"></i>
              </Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/QuomodoSoft">
                <i className="fa-brands fa-linkedin-in"></i>
              </Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/QuomodoSoft">
                <i className="fa-brands fa-youtube"></i>
              </Link>
            </li>
          </ul> */}
        </nav>
      </aside>
    </>
  );
}

export default Drawer;
