// @ts-nocheck
// ignore error ts

import React, { FC, useEffect, useRef, useState } from "react";

type Props = {
  getData: (data: any) => void;
  title: string;
  dataDefault: any;
};

export const EditorForm = (props: Props) => {
  const editorRef = useRef(null);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [value, setValue] = useState<any>(props.dataDefault);
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, //Added .CKEditor
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    props.getData(value);
  }, [value]);

  useEffect(() => {
    setValue(props.dataDefault);
  }, [props.dataDefault]);

  return editorLoaded ? (
    <div className="editor">
      <div className="label">
        <span>{props.title}</span>
      </div>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData();
          setValue(data);
        }}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
          ],
        }}
      />
    </div>
  ) : (
    <></>
  );
};
