import React, { useEffect, FC } from "react";
import TitleSection from "../common/TitleSection";
import FeatureSectionTwo from "../HomeInner/FeatureSectionTwo";
import PricingSection from "../HomeInner/PricingSection";
import ContactSection from "./ContactSection";
import History from "../About/History";
import Sponser from "../HomeInner/Sponser";
import Footer from "../HomeOne/Footer";
import InnerPageWrapper from "../common/InnerPageWrapper";

export const ServicePage: FC = () => {
  useEffect(() => {
    // const selectBody = document.querySelector("body");
    // selectBody.setAttribute("id", "service");
  }, []);
  return (
    <>
      <InnerPageWrapper>
        <TitleSection
          title="Dịch vụ & Kinh doanh"
          paths={[
            { name: "home", path: "/" },
            { name: "service", path: "/service" },
          ]}
        />
        <FeatureSectionTwo />
        <PricingSection />
        {/* <ContactSection /> */}
        {/* <History /> */}
        <Sponser className="sponsers-slider-s2 s-padding-b" />
        <Footer />
      </InnerPageWrapper>
    </>
  );
};
