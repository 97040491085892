import React, { FC } from "react";
import { IInputProps } from "../../../modules";

export const InputText: FC<IInputProps> = ({ onChange, onTouched, value, isDisabled }) => {
  return (
    <input
      type="text"
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => setTimeout(() => onTouched(), 500)}
      value={value}
      disabled={isDisabled}
    />
  );
};
