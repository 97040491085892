/* eslint-disable react/style-prop-object */
import React from "react";
import { Link } from "react-router-dom";
import { getEnv } from "../../../../configs";

function MainSection() {
  return (
    <section className="contact-form-area s-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="contact-info">
              <span className="s-sub-title">Contact With Us</span>
              <h2 className="s-title">Liên hệ với chúng tôi</h2>
              <div className="c-info-box-wrap">
                <Link
                  to={`tel:${getEnv("COMPANY_PHONE")}`}
                  className="c-info-box"
                >
                  <div className="icon">
                    <img
                      src={"/assets/images/feature-icons/phone.png"}
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <span>Gọi chúng tôi</span>
                    <h4>{getEnv("COMPANY_PHONE")}</h4>
                  </div>
                </Link>
                <Link
                  to={`mailto:${getEnv("COMPANY_EMAIL")}`}
                  className="c-info-box"
                >
                  <div className="icon">
                    <img
                      src={"/assets/images/feature-icons/evelope.png"}
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <span>Email cho chúng tôi</span>
                    <h4>{getEnv("COMPANY_EMAIL")}</h4>
                  </div>
                </Link>
                <Link to="#" className="c-info-box">
                  <div className="icon">
                    <img
                      src={"../../assets/images/feature-icons/pin.png"}
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <span>Địa chỉ</span>
                    <h4>{getEnv("COMPANY_ADDRESS")}</h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="contact-form-s1 contact-map">
              {/* <form action="#">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name*"
                                    required
                                />
                                <input
                                    type="number"
                                    name="number"
                                    placeholder="Phone Number*"
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Your Email*"
                                    required
                                />
                                <input
                                    type="text"
                                    name="subject"
                                    placeholder="Your Subject*"
                                    required
                                />
                                <textarea
                                    name="message"
                                    cols="30"
                                    rows="10"
                                    placeholder="Message here"
                                ></textarea>
                                <button type="submit">Submit</button>
                            </form> */}

              <iframe
                frameBorder="0"
                allowFullScreen="1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="Google Map"
                width="641"
                height="598"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.737698737076!2d106.77311787644496!3d10.831374189320846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752701764a4589%3A0x95a23cab4cbb76f0!2zVMSDbmcgTmjGoW4gUGjDuiwgUGjGsOG7m2MgTG9uZyBCLCBRdeG6rW4gOSwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1694573451125!5m2!1sen!2s%22%20width=%22600%22%20height=%22450%22%20style=%22border:0;%22%20allowfullscreen=%22%22%20loading=%22lazy%22%20referrerpolicy=%22no-referrer-when-downgrade%22%3E%3C/iframe%3E"
                id="map-contact"
                className="iframe-map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
