import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getEnv } from "../../../../configs";

function TitleSection({ title, paths = [] }) {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} - {getEnv("WEBSITE_DOMAIN_SHORT")}</title>
        <link rel="canonical" href={getEnv("WEBSITE_DOMAIN")} />
      </Helmet>

      <section className="d-page-banner">
        <div className="container">
          <div className="row">
            <div className="content">
              <h1 style={{ textTransform: "capitalize" }}>{title}</h1>
              <ul className="breadcrumb">
                {paths &&
                  paths.length > 0 &&
                  paths.map((item) => (
                    <li key={Math.random()}>
                      <Link
                        to={item.path}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TitleSection;
