import React, { FC } from "react";
import TitleSection from "../common/TitleSection";
import Footer from "../HomeOne/Footer";
import MainSection from "./MainSection";
import InnerPageWrapper from "../common/InnerPageWrapper";

export const PaymentInfoPage: FC = () => {
  return (
    <InnerPageWrapper>
      <TitleSection
        title="Thông tin thanh toán"
        paths={[
          { name: "home", path: "/" },
          { name: "payment info", path: "/payment-info" },
        ]}
      />
      <MainSection />
      <Footer />
    </InnerPageWrapper>
  );
};
