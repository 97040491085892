import React, { FC } from "react";
import { UserService } from "../../services/user";
import { translate } from "../../languages";
import { Icon } from "../icon";
import { NavLink, useLocation } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { useSelector } from "../../store";

export const Navigator: FC = () => {
  return (
    <div className="Navigator">
      {/* <img src="/assets/images/logo-symbol-white.png" alt="" className="logo" /> */}
      <MenuLinks />
    </div>
  );
};

const MenuLinks: FC = () => {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);
  return (
    <div className="menu">
      <NavLink
        to={Routes.userList.path}
        isActive={() => {
          return (
            pathname.includes("/user-promotion") ||
            pathname.includes("/user-list")
          );
        }}
      >
        <Icon.User />
        Users
      </NavLink>

      <NavLink
        to={Routes.transactionList.path}
        isActive={() => {
          return (
            pathname.includes("/transaction-list") ||
            pathname.includes("/transactions-withdraws")
          );
        }}
      >
        <Icon.TransactionIcon />
        Transactions
      </NavLink>

      {user?.userRole !== "AGENT" && (
        <NavLink
          to={Routes.withdrawConfig.path}
          isActive={() => {
            return (
              pathname.includes("/server-config-withdraw") ||
              pathname.includes("/server-config-change-password") ||
              pathname.includes("/server-config-activities") ||
              pathname.includes("/server-config-maintenance") ||
              pathname.includes("/server-config-role-permission")
            );
          }}
        >
          <Icon.Setting />
          Settings
        </NavLink>
      )}

      <NavLink
        to={Routes.GitlabVariables.path}
        isActive={() => {
          return (
            pathname.includes("/admin/swat-team/gitlab-variables")
          );
        }}
      >
        <Icon.User />
        SwatTeam
      </NavLink>

      <div className="btnLogout" onClick={() => UserService.logout()}>
        <Icon.Logout />
        {translate("logout")}
      </div>
    </div>
  );
};
