/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import SliderCom from "../helpers/SliderCom";

function FeatureSectionSlider() {
    const settings={
        dots: true,
        arrows:false,
        infinite: true,
        autoplay: true,
        prevArrow: '',
        nextArrow: '',
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <section className="features-slider-s3 s-padding">
            <div className="container">
                <div className="s-title-wrap">
                    <span className="s-sub-title">Giải pháp công nghệ</span>
                    <h2 className="s-title">Các sản phẩm & dịch vụ dành cho bạn</h2>
                </div>
                <div className="row feature-s3-inner">
                    <SliderCom settings={settings}>
                        <div className="col-xl-4">
                            <div className="feature-s5">
                                <div className="thumb">
                                    <img src={"/assets/images/thumbs/web-design.jpeg"} alt=""/>
                                </div>
                                <div className="content">
                                    <div className="icon">
                                        <img
                                            src={"/assets/images/feature-icons/feature5-1.png"} alt=""
                                        />
                                    </div>
                                    <h3><a href="#">Thiết kế web</a></h3>
                                    <p>
                                        Lập trình & thiết kế website theo yêu cầu. Đáp ứng sẵn có tính năng đơn giản đến phức tạp
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="feature-s5">
                                <div className="thumb">
                                    <img src={"/assets/images/thumbs/wordpress.jpeg"} alt="wordpress"/>
                                </div>
                                <div className="content">
                                    <div className="icon">
                                        <img
                                            src={"/assets/images/feature-icons/feature5-2.png"} alt=""
                                        />
                                    </div>
                                    <h3><a href="#">Wordpress</a></h3>
                                    <p>
                                        Cấu hình và vận hành website trên nền tảng Wordpress tận dụng kho tính năng khổng lồ
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="feature-s5">
                                <div className="thumb">
                                    <img src={"/assets/images/thumbs/domain-hosting.jpeg"} alt="domain hosting"/>
                                </div>
                                <div className="content">
                                    <div className="icon">
                                        <img
                                            src={"/assets/images/feature-icons/feature5-1.png"} alt=""
                                        />
                                    </div>
                                    <h3><a href="#">Tên miền / Server</a></h3>
                                    <p>
                                        Hỗ trợ đăng ký tên miền, hosting & vận hành server hàng năm với chi phí cạnh tranh
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="feature-s5">
                                <div className="thumb">
                                    <img src={"/assets/images/thumbs/email-domain.png"} alt="email domain"/>
                                </div>
                                <div className="content">
                                    <div className="icon">
                                        <img
                                            src={"/assets/images/feature-icons/feature5-1.png"} alt=""
                                        />
                                    </div>
                                    <h3><a href="#">Email theo tên miền</a></h3>
                                    <p>
                                        Tạo nhiều địa chỉ email theo tên miền công ty, phân quyền người dùng và nhiều tiện ích bổ sung
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="feature-s5">
                                <div className="thumb">
                                    <img src={"/assets/images/thumbs/mobile-app.png"} alt="mobile-app"/>
                                </div>
                                <div className="content">
                                    <div className="icon">
                                        <img
                                            src={"/assets/images/feature-icons/feature5-3.png"} alt=""
                                        />
                                    </div>
                                    <h3><a href="#">Mobile App</a></h3>
                                    <p>
                                        Sử dụng các ngôn ngữ lập trình phổ biến hiện nay để phát hiện ứng dụng mobile đa nền tảng, tiết kiệm chi phí
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="feature-s5">
                                <div className="thumb">
                                    <img src={"/assets/images/thumbs/investment-management-system.png"} alt="Investment Management System"/>
                                </div>
                                <div className="content">
                                    <div className="icon">
                                        <img
                                            src={"/assets/images/feature-icons/feature5-3.png"} alt=""
                                        />
                                    </div>
                                    <h3><a href="#">Hệ thống đầu tư</a></h3>
                                    <p>
                                        Xây dựng hệ thống ứng dụng web cho phép user đầu tư, giải pháp nạp & rút và theo dõi dòng tiền liên tục
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="feature-s5">
                                <div className="thumb">
                                    <img src={"/assets/images/thumbs/seo-marketing.png"} alt="SEO Marketing"/>
                                </div>
                                <div className="content">
                                    <div className="icon">
                                        <img
                                            src={"/assets/images/feature-icons/feature5-3.png"} alt=""
                                        />
                                    </div>
                                    <h3><a href="#">SEO Marketing</a></h3>
                                    <p>
                                        Hỗ trợ chạy marketing trên các nên tảng phổ biến hiện nay như: Google, Facebook, Tiktok,...
                                    </p>
                                </div>
                            </div>
                        </div>
                    </SliderCom>
                </div>
            </div>
        </section>
    );
}

export default FeatureSectionSlider;