import React, { FC, Fragment, useState } from "react";
import { ClassNames } from "../../modules";

export interface ITab {
  label: string;
  id: string;
  data?: any;
  subId?: string;
}

export interface ITabsProps {
  tabs: ITab[];
  render: (tab: ITab) => any;
}

export const Tabs: FC<ITabsProps> = (props) => {
  const [tabActive, setTabActive] = useState(props.tabs[0]);

  const handleChange = (state: any) => {
    setTabActive(state);
  };

  return (
    <Fragment>
      <div className="Tabs">
        {props.tabs.map((item, key) => {
          return (
            <div
              key={key}
              className={ClassNames({
                item: true,
                active: tabActive.id === item.id,
              })}
              onClick={() => handleChange(item)}
            >
              {item.label}
            </div>
          );
        })}
      </div>

      {props.render(tabActive)}
    </Fragment>
  );
};
