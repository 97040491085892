import React, { useState, FC, useRef } from "react";
import TitleSection from "../common/TitleSection";
import FeatureSection from "../HomeInner/FeatureSection";
import AboutSection from "./AboutSection";
import History from "./History";
import TeamSection from "../HomeInner/TeamSection";
import Testimonial from "./Testimonial";
import Sponser from "../HomeInner/Sponser";
import Footer from "../HomeOne/Footer";
import InnerPageWrapper from "../common/InnerPageWrapper";

export const AboutPage: FC = () => {

  return (
    <>
      <InnerPageWrapper>
        <TitleSection   
          title="Về chúng tôi"
          paths={[
            { name: "Home", path: "/" },
            { name: "About us", path: "/about" },
          ]}
        />
        {/* <FeatureSection /> */}
        {/* <AboutSection /> */}
        <History />
        {/* <TeamSection className="s-padding-b" /> */}
        {/* <Testimonial /> */}
        <Sponser className="sponsers-slider-s2 s-padding-b" />
        <Footer />
      </InnerPageWrapper>
    </>
  );
};
