/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import NavItems from "../common/NavItems";
import { getEnv } from "../../../../configs";

function Header() {
  return (
    <header className="header-s3">
      <div className="header-container">
        <div className="header-top-container">
          <div className="container">
            <div className="header-s3__top">
              <div className="p-left">
                <div className="tb-single-info-s2">
                  <div className="icon">
                    <i className="fa-solid fa-phone"></i>
                  </div>
                  <div className="content">
                    <p>
                      <a href={`tel:${getEnv("COMPANY_PHONE")}`}>
                        {getEnv("COMPANY_PHONE")}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="tb-single-info-s2">
                  <div className="icon">
                    <i className="fa-solid fa-envelope"></i>
                  </div>
                  <div className="content">
                    <p>
                      <a href={`mailto:${getEnv("COMPANY_EMAIL")}`}>
                        {getEnv("COMPANY_EMAIL")}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="tb-single-info-s2">
                  <div className="icon">
                    <i className="fa-solid fa-location-dot"></i>
                  </div>
                  <div className="content">
                    <p>
                      <a href={getEnv("COMPANY_MAP")} target="_blank">
                        {getEnv("COMPANY_ADDRESS")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-right">
                <ul className="social-icons-s1">
                  <li>
                    <a href={getEnv("COMPANY_FACEBOOK")} target="_blank">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  {/* <li>
                                        <a href="https://www.facebook.com/QuomodoSoft"
                                        ><i className="fa-brands fa-twitter"></i
                                        ></a>
                                    </li> */}
                  <li>
                    <a href={getEnv("COMPANY_LINKEDIN")} target="_blank">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                  {/* <li>
                                        <a href="https://www.facebook.com/QuomodoSoft"
                                        ><i className="fa-brands fa-youtube"></i
                                        ></a>
                                    </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom-container">
          <div className="container">
            <div className="header-s3__bottom">
              <div className="p-left">
                <div className="logo">
                  <a href="/">
                    <img
                      src="../../assets/images/logos/logo-white-2.png"
                      alt="www.steady.vn"
                    />
                  </a>
                </div>
              </div>
              <div className="p-center">
                <NavItems />
              </div>
              <div className="p-right">
                <a href="/service" className="btn btn-s3">
                  Start a Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
