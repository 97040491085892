const defaultState = false;

export const SET_DETAIL_NEWS = "SET_DETAIL_NEWS";

export const detailNewsReducer = (state = defaultState, action: any) => {
  const { type, data, error } = action;
  if (type === SET_DETAIL_NEWS) {
    if (data) return data;
    if (error) return { error };
  }
  return state;
};
