import React from "react";
import { InputCheckbox } from "../../../../components";
import { CreateAlert, onError } from "../../../../modules";
import { ConfigService } from "../../../../services/config/config.service";
import { store, useSelector } from "../../../../store";
import { withConfigWraper } from "../wraper";

export const MaintenanceConfig = withConfigWraper(() => {
  const maintenanceConfig = useSelector((state) => state.maintenanceConfig);
  const maintenanceJobServiceConfig = useSelector((state) => state.maintenanceJobServiceConfig);

  const checkConfig = (typeMaintenance: string) => {
    if (typeMaintenance === "Maintenance Server") {
      return ConfigService.swithMaintenanceServer({
        enable: !maintenanceConfig,
      })
        .then(async () => {
          CreateAlert({ type: "success", message: "Set config maintenance successful." });
          await ConfigService.getServerConfigMaintenance(store);
        })
        .catch(onError);
    }
    if (typeMaintenance === "Maintenance Job Service") {
      return ConfigService.swithMaintenanceJobService(!maintenanceJobServiceConfig)
        .then(async () => {
          CreateAlert({ type: "success", message: "Set config maintenance successful." });
          await ConfigService.getJobServiceConfigMaintenance(store);
        })
        .catch(onError);
    }
  };

  return (
    <div className="row mb20">
      {["Maintenance Server", "Maintenance Job Service"].map((item: any, idx: number) => (
        <div className="col-sm-2" key={idx}>
          <InputCheckbox
            type={"checkbox"}
            label={item}
            value={item === "Maintenance Server" ? maintenanceConfig : maintenanceJobServiceConfig}
            onChange={() => checkConfig(item)}
            name=""
            onTouched={() => checkConfig(item)}
          />
        </div>
      ))}
    </div>
  );
});
