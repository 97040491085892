/* eslint-disable react/style-prop-object */
import React from "react";
import { getEnv } from "../../../../configs";

function AboutSection() {
  return (
    <section className="about-s4">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="about-s4__content">
              <span className="s-sub-title">Đội ngũ nhiều năm kinh nghiệm</span>
              <h2 className="s-title">Gia công phần mềm theo yêu cầu</h2>
              <p className="desc">
                Chúng tôi may mắn là những người đi tiên phong trong lĩnh vực
                công nghệ, đặc biệt về website & mobile application. Ứng dụng
                công nghệ blockchain vào việc lưu trữ, bảo mật một lượng thông
                tin khổng lồ về các giao dịch trên hệ thống.
              </p>
              <div className="content-b">
                <div className="i-box">
                  <div className="icon">
                    <img
                      src={"/assets/images/feature-icons/network-icon-blue.png"}
                      alt=""
                    />
                  </div>
                  <p>Website & Mobile Application</p>
                </div>
                <div className="i-box">
                  <div className="icon">
                    <img
                      src={"/assets/images/feature-icons/globe-icon-blue.png"}
                      alt=""
                    />
                  </div>
                  <p>Blockchain Technology</p>
                </div>
              </div>
              <a
                href={getEnv("COMPANY_TELEGRAM")}
                className="btn btn-s3"
                target="_blank"
              >
                Hỗ trợ Telegram
              </a>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="about-s4__thumb">
              <div className="p-left">
                <img
                  src={
                    "https://media.istockphoto.com/id/1196702694/photo/designers-drawing-website-ux-app-development.jpg?s=612x612&w=0&k=20&c=nMyBxZuA19Lfow_eiUpZ1UAgibB4VJgR6t_7_e-tyMc="
                  }
                  alt=""
                  className="img1"
                />
                <img
                  src={
                    "https://d3a0xmeleyf7lg.cloudfront.net/others/2022-11-03T05%3A48%3A47.502Z-beautiful-cryptocurrency-hologram-design.jpg"
                  }
                  alt=""
                  className="img2 img2-hologram-design"
                />
              </div>
              <div className="p-right">
                <img
                  src={
                    "/assets/images/thumbs/about4-t2.d1cec50be2c0cb9f76a9 (1).png"
                  }
                  alt=""
                />
              </div>
              <div className="shape">
                <img
                  src={"/assets/images/backgrounds/about-4-shape.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
