import React, { FC, Fragment, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Navigator } from "../components";
import { UserHead } from "../components/head";
import { MainService } from "../services/main";
import { useSelector } from "../store";

export interface IPageProps extends RouteComponentProps {
  user: any;
}

export const withPageWraper = (Component: FC<IPageProps>) => (props: IPageProps) => {
  const user = useSelector((state) => state.user);
  const serverTime = useSelector((state) => state.main.serverTime);
  const userPageInitializeStatus = useSelector((state) => state.main.userPageInitializeStatus);

  useEffect(() => {
    if (userPageInitializeStatus === "pending") MainService.initialUserPage();
    if (userPageInitializeStatus === "completed") {
      if (!user) {
        localStorage.setItem("auth-redirect-to", window.location.pathname);
        props.history.push("/authentication/login");
      }
    }
  }, [user, userPageInitializeStatus, props.history]);

  if (user && userPageInitializeStatus === "completed" && serverTime)
    return (
      <Fragment>
        <div className="UserWraper">
          <Navigator />
          <UserHead />

          <div className="content pdb16 pdl32 pdr32">
            <Component {...props} user={user} />
          </div>
        </div>
      </Fragment>
    );

  return (
    <div className="UserWraper loading">
      <div className="loading">
        {/* <img src="/assets/images/logo-symbol-white.png" alt="" className="logo" /> */}
      </div>
    </div>
  );
};
