import _ from "lodash";
import React, { FC } from "react";
import NumberFormat from "react-number-format";
import { IInputProps } from "../../../modules";

export const InputNumber: FC<IInputProps> = ({ value, onChange, onTouched, decima }) => {
  return (
    <NumberFormat
      fixedDecimalScale={true}
      decimalSeparator="."
      thousandSeparator=","
      value={typeof value === "number" ? value : undefined}
      onValueChange={({ floatValue }) => {
        const data = floatValue || 0;
        onChange(data);
      }}
      onBlur={() => setTimeout(() => onTouched(), 500)}
      decimalScale={_.isNil(decima) ? 4 : decima}
    />
  );
};
