import React from "react";
import { Link } from "react-router-dom";
import {
  CreateAlert,
} from "../../../../modules";

function ServiceSidebar() {
  const handleGetLicense = async () => {
    CreateAlert({
      type: "success",
      message: "Vui lòng liên hệ bộ phận CSKH",
    });
  };

  return (
    <>
      {/* <div className="service-c-list">
        <h3 className="title">Category</h3>
        <ul className="c-list">
          <li>
            <Link to="#">
              <span className="icon">
                <img src={"/assets/images/feature-icons/cloud.svg"} alt="" />
              </span>
              <span className="text">Cloud Solutions</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <span className="icon">
                <img src={"/assets/images/feature-icons/network.svg"} alt="" />
              </span>
              <span className="text">Cyber Security</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <span className="icon">
                <img
                  src={"/assets/images/feature-icons/data-recovery.svg"}
                  alt=""
                />
              </span>
              <span className="text">Data Recovery</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <span className="icon">
                <img src={"/assets/images/feature-icons/code.svg"} alt="" />
              </span>
              <span className="text">Web Development</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <span className="icon">
                <img src={"/assets/images/feature-icons/pencil.svg"} alt="" />
              </span>
              <span className="text">Product & Design</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <span className="icon">
                <img src={"/assets/images/feature-icons/search.svg"} alt="" />
              </span>
              <span className="text">Analytic Solutions</span>
            </Link>
          </li>
        </ul>
      </div> */}
      <div className="service-c-list">
        <h3 className="title">Tra cứu MST</h3>
        <ul className="c-list download">
          <li>
            <a href="https://masothue.com/0317942779-cong-ty-tnhh-steady-partner?zarsrc=31&utm_source=zalo&utm_medium=zalo&utm_campaign=zalo&gidzl=udtm1Aczsn_bPVS3fQFU7UzZzmQufuaS-6IXKh6YX4pxPArMjlU5JlmohLtZ_OSM-3ciNMLvzXqggBdT6G" target="_blank">
              <span className="icon">
                <img src={"/assets/images/feature-icons/networks-icon.svg"} alt="" />
              </span>
              <span className="text">0317942779</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="service-c-list">
        <h3 className="title">Giấy phép kinh doanh</h3>
        <ul className="c-list download">
          <li>
            <a onClick={handleGetLicense}>
              <span className="icon">
                <img src={"/assets/images/feature-icons/pdf.svg"} alt="" />
              </span>
              <span className="text">Tải file PDF</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default ServiceSidebar;
