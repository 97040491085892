const defaultState = null;

export const GET_RANK_CONFIG_WITHDRAW = "GET_RANK_CONFIG_WITHDRAW";
export const GET_RANK_CONFIG_WITHDRAW_ERROR = "GET_RANK_CONFIG_WITHDRAW_ERROR";
export const rankConfigWithdrawReducer = (state = defaultState, action: any) => {
  const { type } = action;
  if (type === GET_RANK_CONFIG_WITHDRAW) return action.data;
  if (type === GET_RANK_CONFIG_WITHDRAW_ERROR) return defaultState;
  return state;
};
