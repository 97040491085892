import React from 'react';

function Sponser({className}) {
    return (
        <div className={`sponsers-slider-s1 s-padding ${className||""}`}>
            <div className="container">
                <div className="s-title-wrap">
                    <span className="s-sub-title">Đối tác & Công nghệ</span>
                    <h2 className="s-title">Đang được chúng tôi sử dụng phát triển dự án</h2>
                </div>
                <div className="row">
                    <div className="sponsers-container">
                        <div className="s-sponser">
                            <img src={"/assets/images/brand-icons/tech-stack/dotnetcore.png"} alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/nodejs.png" alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/docker.png" alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/reactjs.png" alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/nextjs.png" alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/postgres.png" alt=""/>
                        </div>

                       
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/mysql.png" alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/docker-compose.png" alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/gitlab.png" alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/ethereum.png" alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/binance-smart-chain.jpeg" alt=""/>
                        </div>
                        <div className="s-sponser">
                            <img src="../../assets/images/brand-icons/tech-stack/tron.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sponser;