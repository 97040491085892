import React from "react";

function History() {
  return (
    <section className="about-s7 s-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-s7__content">
              <span className="s-sub-title">Giới thiệu</span>
              <h2 className="s-title">Đôi điều về chúng tôi</h2>
              <div className="info-list-s2">
                <div className="s-info">
                  <p className="title">Hiện tại</p>
                  <p>
                    Tận dụng những kinh nghiệm của chúng tôi trong việc phát
                    triển phần mềm, thiết kế giải pháp, tích hợp hệ thống…để cải
                    tiến quy trình doanh nghiệp của bạn.
                  </p>
                </div>
                <div className="s-info">
                  <p className="title">Sứ mệnh của chúng tôi</p>
                  <p>
                    Là giúp số hóa các nghiệp vụ quản lý cho các doanh nghiệp
                    vừa và lớn. Giúp các doanh nghiệp dễ dàng tiếp cận các công
                    nghệ mới và xây dựng những ứng dụng doanh nghiệp với
                    đầy đủ tiêu chuẩn.
                  </p>
                </div>
                <div className="s-info">
                  <p className="title">
                    Steady Partner được thành lập từ năm 2023
                  </p>
                  <p>
                    Hoạt động trong lĩnh vực phần mềm, chuyên cung cấp các giải
                    pháp trực tuyến, website và ứng dụng di động.
                  </p>
                  <p>
                    Với hơn 10 thành viên, chúng tôi hội tụ đủ các lập trình
                    viên, thiết kế chuyên về lĩnh vực phần mềm và di động.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-s7__thumb">
              <div className="p-left">
                <img src={"../../assets/images/about/6.jpeg"} alt="" />
                <img src={"../../assets/images/about/7.jpeg"} alt="" />
                <img src={"../../assets/images/about/9.jpeg"} alt="" />
              </div>
              <div className="p-right">
                <img src={"../../assets/images/about/8.jpeg"} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default History;
