import React, { useState, FC, useRef } from "react";
import * as Yup from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"; //google recaptcha v3
// import ReCAPTCHA from "react-google-recaptcha"; //google recaptcha v2

import { UserService } from "../../../services/user";
import { useForm, InputWraper, Button, CreateAlert } from "../../../modules";
import { InputText, InputPassword } from "../../../components";
import { AuthWraper } from "../authWraper";
import { getLocaleKey, translate } from "../../../languages";
import { getEnv } from "../../../configs";
import _ from "lodash";

export const PageAuthLogin: FC = () => {
  const [dataPuzzleImg, setDataPuzzleImg] = useState<any>();
  const [captchaUid, setCaptchaUid] = useState<any>();
  const { executeRecaptcha } = useGoogleReCaptcha() as any; //google recaptcha v3
  // const recaptchaRef = useRef<any>(null); //google recaptcha v2

  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "email",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "password",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      // {
      //   name: "secretCode",
      //   validate: Yup.string().required(translate("must-be-provided")),
      // },
      {
        name: "puzzleAnswer",
        validate: (dataPuzzleImg != null) ? Yup.string().required(translate("must-be-provided")) : null
      }
    ],
    onSubmit: async (values) => {
      let GoogleRecaptchaToken;

      try {
          // recaptchaRef?.current?.reset(); //google recaptcha v2
          // GoogleRecaptchaToken = await recaptchaRef?.current?.executeAsync(); //google recaptcha v2
          GoogleRecaptchaToken = await executeRecaptcha("login");
      } catch (error) {
          console.log(error);
      }

      let payload = {
        email: values.email, 
        password: values.password, 
        secretCode:values.secretCode, 
        recaptcha: GoogleRecaptchaToken,
        captchaUid: captchaUid,
        puzzleAnswer: values?.puzzleAnswer
      }
      return UserService.login(payload).catch((err) => {

        if (_.isError( _.attempt(JSON.parse, err.message)) === false) {
            let messageJSON = JSON.parse(err.message);
            if ((messageJSON?.message === 'REQUIRED_SOLVE_PUZZLE')) {
              setDataPuzzleImg(messageJSON?.puzzleImg);
              setCaptchaUid(messageJSON?.captchaUid);
              return;
            }
            if ((messageJSON?.message === 'SOLVE_PUZZLE_INCORRECTLY')) {
              setDataPuzzleImg(messageJSON?.puzzleImg);
              setCaptchaUid(messageJSON?.captchaUid);
              CreateAlert({ message: translate(messageJSON.message), type: "danger" });
              return;
            }
            if (messageJSON?.detail?.includes('timeout-or-duplicate') === true) { //if token Google reCaptcha expired then get new token and call api
                handleSubmit(); 
                return;
            }
            if (_.isPlainObject(messageJSON)) {
                CreateAlert({ message: messageJSON.message, type: "danger" });
                return;
            }
        }

        if (dataPuzzleImg != null) { //if any info incorrect, also refresh puzzle
          UserService.getPuzzleCaptcha({
            captchaUid: captchaUid
          }).then((res:any) => {
            setDataPuzzleImg(res?.result?.puzzleImg);
            setCaptchaUid(res?.result?.captchaUid);
          })
        }
        
        CreateAlert({ message: translate(err.message), type: "danger" });
        throw err;
      });
    },
  });

  return (
    <AuthWraper>
      <div className="page-auth-login container-fluid">
        <div style={{width: '100%'}} className="row">
          <div className="col-lg-4 order-lg-1 col-md-12 order-md-2 order-2 d-flex align-items-center">
            <div className="login-form">
              <form onSubmit={handleSubmit}>
                {/* <div className="logo">
                  <img className="logo" src="/assets/images/logo-white.png" alt="" />
                </div> */}
                <InputWraper
                  label={translate("email-address")}
                  inputProps={getInputProps("email")}
                  component={InputText}
                />
                <InputWraper
                  label={translate("password")}
                  inputProps={getInputProps("password")}
                  component={InputPassword}
                />
                {/* <InputWraper
                  label={"Secret Code"}
                  inputProps={getInputProps("secretCode")}
                  component={InputPassword}
                /> */}
                {dataPuzzleImg && <div className="user-row">
                  <div className="user-col-18">
                    <div style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: dataPuzzleImg}}></div>
                  </div>
                  <div className="user-col-18">
                    <InputWraper
                      label={"Puzzle Answer"}
                      inputProps={getInputProps("puzzleAnswer")}
                      component={InputText}
                    />
                  </div>
                </div>}
                <Button label={translate("login")} type="submit" isLoading={isSubmitting} />
                {/* <ReCAPTCHA //google recaptcha v2
                    ref={recaptchaRef}
                    sitekey={getEnv("GOOGLE_RECAPTCHA_KEY")}
                    size="invisible"
                    hl={getLocaleKey()}
                    badge={'bottomright'}
                /> */}
              </form>
            </div>
          </div>
          <div className="col-lg-8 order-lg-2 col-md-12 order-md-1 order-1">
            <div className="banner-image">
              <img className="banner-image__img" src="/assets/images/primary-banner-login.png" alt="" />
            </div>
          </div>
        </div>
        {/* <img src="/assets/images/maps.png" className="background" alt="" />

        <form onSubmit={handleSubmit}>
          <div className="head">
            <img className="logo" src="/assets/images/logo-white.png" alt="" />
            <div className="title">{translate("login-to-your-account")}</div>
          </div>

          <GoogleReCaptcha onVerify={(token) => setRecaptcha(token)} />

          <InputWraper
            label={translate("email-address")}
            inputProps={getInputProps("email")}
            component={InputText}
          />

          <InputWraper
            label={translate("password")}
            inputProps={getInputProps("password")}
            component={InputPassword}
          />

          <Button label={translate("login")} type="submit" isLoading={isSubmitting} />
        </form> */}
      </div>
    </AuthWraper>
  );
};
