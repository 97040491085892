import React from "react";
import {
  Button,
  CreateAlert,
  ITableStructureItem,
  Icon,
  InputWraper,
  NumberUtils,
  Table,
  useForm,
} from "../../../../modules";

function Hero() {
  const handlePlayVideo = async () => {
    CreateAlert({
      type: "success",
      message: "Video đang không có sẵn",
    });
  };

  return (
    <section className="hero-s3">
      <div className="container">
        <div className="row">
          <div className="col-xxl-7 col-xl-6 col-lg-6 hero-s3__content">
            <h2 className="content-sh">Chúng tôi, STEADY PARTNER CO., LTD</h2>
            <h1 className="content-h">Cung cấp giải pháp phần mềm tinh gọn</h1>
            <p className="content-d">
              Cho thuê & quản trị cloud server, đăng ký tên miền, email gắn theo tên
              miền doanh nghiệp, xây dựng hệ thống - website với lượng truy cập
              lên đến hàng nghìn users...
            </p>
            <div className="btn-group">
              <a href="/service" className="btn btn-s3">
                Tìm hiểu thêm
              </a>

              <div className="video-popup" id="videoPopup1">
                <div className="video-popup-inner">
                  <button type="button" className="cls-btn">
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                  <input type="text" />
                </div>
              </div>
              <button
                type="button"
                className="play-btn-sm v-play-btn"
                data-video-id="videoPopup1"
                onClick={handlePlayVideo}
              >
                <i className="fa-solid fa-play"></i>
              </button>
            </div>
          </div>
          <div className="col-xxl-5 col-xl-6 col-lg-6 hero-s3__thumb d-lg-none">
            <div className="img">
              <img src="../../assets/images/hero-img/3.webp" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="shape">
        <img src="../../assets/images/backgrounds/hero-3-shape.png" alt="" />
      </div>
    </section>
  );
}

export default Hero;
