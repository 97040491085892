import React, { useEffect, FC } from "react";
import Header from "./Header";
import Hero from "./Hero";
import FeatureSection from "./FeatureSection";
import AboutSection from "./AboutSection";
import AboutSectionTwo from "./AboutSectionTwo";
import FeatureSectionSlider from "./FeatureSectionSlider";
import FunFact from "./FunFact";
import ProjectSlider from "./ProjectSlider";
import TestimonialSection from "./TestimonialSection";
import BlogSection from "./BlogSection";
import Footer from "./Footer";
import Drawer from "../Mobile/Drawer";
import { Helmet } from "react-helmet";
import { getEnv } from "../../../../configs";

export const HomePage: FC = () => {
  useEffect(() => {
    let selectBody = document.querySelector("body");
    selectBody?.setAttribute("id", "home-3");
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - {getEnv("WEBSITE_DOMAIN_SHORT")}</title>
        <link rel="canonical" href={getEnv("WEBSITE_DOMAIN")} />
      </Helmet>

      <Header />
      <Drawer />
      <Hero />
      <FeatureSection />
      <AboutSection />
      <AboutSectionTwo />
      <FeatureSectionSlider />
      {/* <FunFact /> */}
      {/* <ProjectSlider /> */}
      {/* <TestimonialSection /> */}
      {/* <BlogSection /> */}
      <Footer />
    </>
  );
};
