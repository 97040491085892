import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import {
  InputNumber,
  InputText,
  InputCheckbox,
  InputPassword,
  InputSelect,
} from "../../../../components";
import { translate } from "../../../../languages";
import {
  CreateAlert,
  InputWraper,
  onError,
  useForm,
  Button,
  ITableStructureItem,
  Table,
} from "../../../../modules";
import { AdminGitlabVariablesService } from "../../../../services/admin/swatteam/gitlab-variables.service";
import { store, useSelector } from "../../../../store";
import { withSwatTeamWraper } from "../wraper";
import moment from "moment";
import { isEmpty, isNil, isNull } from "lodash";

export const EnvironmentScopeOptions = [
  { label: "*", value: "*" },
  { label: "development", value: "development" },
  { label: "production", value: "production" },
];

export const GitlabVariables = withSwatTeamWraper(() => {
  const withdrawConfig = useSelector((state) => state.withdrawConfig);
  const user = useSelector((state) => state.user);
  const [isGroupProject, setIsGroupProject] = useState(false);
  const [environmentScope, setEnvironmentScope] = useState("*");
  const [environmentScopeProd, setEnvironmentScopeProd] = useState(null as any);
  const [isShowEnvironmentScopeProd, setIsShowEnvironmentScopeProd] =
    useState(false);
  const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);

  const { handleSubmit, getInputProps, isSubmitting, setValue } = useForm({
    structure: [
      {
        name: "gitlabDomain",
        defaultValue: "https://gitlab",
        validate: Yup.string().required(translate("must be provided")),
      },
      {
        name: "gitlabToken",
        defaultValue: "-",
        validate: Yup.string().required(translate("must be provided")),
      },
      // {
      //   name: "isGroup",
      //   defaultValue: false,
      //   validate: Yup.boolean().required(translate("must be provided")),
      // },
      {
        name: "groupProjectName",
        defaultValue: "-",
        validate: Yup.string().required(translate("must be provided")),
      },
      {
        name: "groupProjectId",
        defaultValue: -1,
        validate: Yup.number().required(translate("must be provided")),
      },
      {
        name: "key",
        defaultValue: "",
        validate: Yup.string().required(translate("must be provided")),
      },
      {
        name: "value",
        defaultValue: "",
        validate: Yup.string().required(translate("must be provided")),
      },
      // {
      //   name: "keyProd",
      //   defaultValue: "",
      //   // isDisabled: true

      // },
      {
        name: "valueProd",
        defaultValue: "",
        validate:
          isShowEnvironmentScopeProd &&
          Yup.string().required(translate("must be provided")),
      },
    ],
    onSubmit: async (values) => {
      const payloadDev = {
        gitlabDomain: values.gitlabDomain,
        gitlabToken: values.gitlabToken,
        isGroup: isGroupProject,
        groupProjectName: values.groupProjectName,
        groupProjectId: values.groupProjectId,
        key: values.key,
        value: values.value,
        environmentScope: environmentScope,
      };
      return AdminGitlabVariablesService.post(payloadDev)
        .then(async () => {
          //CreateAlert({ type: "success", message: "Submit successfully!" });
          if (!isNil(environmentScopeProd)) {
            const payloadProd = {
              gitlabDomain: values.gitlabDomain,
              gitlabToken: values.gitlabToken,
              isGroup: isGroupProject,
              groupProjectName: values.groupProjectName,
              groupProjectId: values.groupProjectId,
              key: values.key,
              value: values.valueProd,
              environmentScope: environmentScopeProd,
            };
            return AdminGitlabVariablesService.post(payloadProd)
              .then(async () => {
                CreateAlert({
                  type: "success",
                  message: "Submit successfully both!",
                });
              })
              .catch(onError);
          } else {
            CreateAlert({
              type: "success",
              message: "Submit successfully only!",
            });
          }
        })
        .catch(onError)
        .then(() => setForceUpdateTable((state) => !state));
    },
  });

  const checkGroupProject = () => {
    if (isGroupProject) {
      setIsGroupProject(false);
    } else {
      setIsGroupProject(true);
    }
  };

  const structure: ITableStructureItem[] = [
    // {
    //   name: "ID",
    //   key: "gitlabVariableHistoryId",
    // },
    {
      name: "gitlabDomain",
      key: "gitlabDomain",
    },
    {
      name: "isGroup",
      key: "isGroup",
    },
    {
      name: "groupProjectName",
      key: "groupProjectName",
    },
    {
      name: "groupProjectId",
      key: "groupProjectId",
    },
    {
      name: "key",
      key: "key",
    },
    {
      name: "value",
      key: "value",
    },
    {
      name: "environmentScope",
      key: "environmentScope",
    },
    {
      name: "created",
      render: (item) => {
        const date = new Date(item.created);
        return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
      },
    },
  ];

  return (
    <div className="mb20">
      <span className="config--title mt40 mb10">Update Gitlab Variables</span>
      <form className="blockLists" onSubmit={handleSubmit}>
        <div className="row config--item ml3 mr3">
          <div className="col-md-12">
            <InputWraper
              label={"gitlabDomain"}
              inputProps={getInputProps("gitlabDomain")}
              component={InputText}
            />
            <InputWraper
              label={"gitlabToken"}
              inputProps={getInputProps("gitlabToken")}
              component={InputPassword}
            />

            <InputCheckbox
              defaultValue={false}
              type={"checkbox"}
              label={"isGroup"}
              value={isGroupProject}
              onChange={checkGroupProject}
              name="check"
              onTouched={checkGroupProject}
            />

            <InputWraper
              label={"groupProjectName"}
              inputProps={getInputProps("groupProjectName")}
              component={InputText}
            />
            <InputWraper
              label={"groupProjectId"}
              inputProps={getInputProps("groupProjectId")}
              component={InputNumber}
            />

            <div className="row">
              <div className="col-md-6">
                <div className="input InputWraper">
                  <div className="label">environmentScope (dev)</div>
                  <InputSelect
                    // onChange={setEnvironmentScope}
                    onTouched={() => false}
                    options={EnvironmentScopeOptions}
                    name="environmentScope"
                    value={environmentScope}
                    isClearable={false}
                    // isSearchable={false}
                    onChange={async (value) => {
                      setEnvironmentScope(value);
                      if (value === "development") {
                        setIsShowEnvironmentScopeProd(true);
                        setEnvironmentScopeProd("production");
                      } else {
                        setIsShowEnvironmentScopeProd(false);
                        setEnvironmentScopeProd(null as any);
                      }
                    }}
                  />
                </div>

                <InputWraper
                  label={"key"}
                  inputProps={getInputProps("key")}
                  component={InputText}
                />
                <InputWraper
                  label={"value"}
                  inputProps={getInputProps("value")}
                  component={InputText}
                />
              </div>
              {isShowEnvironmentScopeProd && (
                <div className="col-md-6">
                  <div className="input InputWraper">
                    <div className="label">environmentScope (prod)</div>
                    <InputSelect
                      onChange={setEnvironmentScopeProd}
                      onTouched={() => false}
                      options={EnvironmentScopeOptions}
                      name="environmentScopeProd"
                      value={environmentScopeProd}
                      isClearable={false}
                      // isSearchable={false}
                      isDisabled={true}
                    />
                  </div>

                  <InputWraper
                    label={"key (prod)"}
                    inputProps={getInputProps("key")}
                    component={InputText}
                    isDisabled={true}
                  />
                  <InputWraper
                    label={"value (prod)"}
                    inputProps={getInputProps("valueProd")}
                    component={InputText}
                    // isRequired={isShowEnvironmentScopeProd}
                  />
                </div>
              )}
            </div>
          </div>

          <div>
            <Button
              label="Submit"
              isMiddle
              buttonType="success"
              className="btnBan"
              type="submit"
              isLoading={isSubmitting}
            />
          </div>
        </div>
      </form>

      <div>
        <span className="config--title mt40 mb10">Histories</span>
        <div className="row ">
          <div className="col-sm-12">
            <Table
              hasOrderColumn
              itemPerPages={10}
              structure={structure}
              forceUpdateTable={forceUpdateTable}
              fetchData={async (params) => {
                // if (params["fromDate"])
                //   params["fromDate"] = new Date(params["fromDate"]);
                // if (params["toDate"])
                //   params["toDate"] = new Date(params["toDate"]);
                const res = AdminGitlabVariablesService.get({
                  page: params.pageNumber,
                  pageSize: params.limit,
                  ...params,
                });

                const data = (await res).data;
                if (data.length > 0) {
                  // console.log(data[0].gitlabDomain);
                  const latest = data[0];
                  if (latest) {
                    setValue("gitlabDomain", latest.gitlabDomain);
                    setValue("gitlabToken", latest.gitlabToken);
                    // console.log('latest.isGroupProject', latest);
                    
                    setIsGroupProject(latest.isGroup);
                    setValue("groupProjectName", latest.groupProjectName);
                    setValue("groupProjectId", latest.groupProjectId);
                  }
                }

                return res;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
