import { RequestMainService } from "../../request";
import { CookieService, ECookieVariable } from "../../cookie";
import { ObjectUtils } from "../../../modules";
import moment from "moment";

export class AdminGitlabVariablesService {
  static async get(params: any = {}) {
    return RequestMainService.get(`/admin/swat-team/variables`, params).then(({ result }) => ({
      count: result.total,
      data: result.data
    }));
  }

  static async post(payload: any) {
    return RequestMainService.post(`/admin/swat-team/variables`, payload);
  }
}
