import { GET_ROLE_PERMISSION_CONFIG, GET_ROLE_PERMISSION_CONFIG_ERROR } from "./rolePermission";
import { Store } from "redux";
import { CookieService, ECookieVariable } from "../cookie";
import { RequestMainService } from "../request";
import { GET_MAINTENANCE_CONFIG, GET_MAINTENANCE_CONFIG_ERROR } from "./maintenance.reduce";
import { GET_WITHDRAW_CONFIG, GET_WITHDRAW_CONFIG_ERROR } from "./withdraw.reduce";
import { GET_RANK_CONFIG_WITHDRAW, GET_RANK_CONFIG_WITHDRAW_ERROR } from "./rankConfigWithdraw.reduce";
import { GET_MAINTENANCE_JOB_SERVICE, GET_MAINTENANCE_JOB_SERVICE_ERROR } from "./maintenanceJobService.reduce";
export class ConfigService {
  static async getServerConfigWithdraw(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/get-server-config`)
        .then(({ result }) => {
          const data = result.find((v: any) => v.serverConfigName === "WITHDRAW_LIMIT_CONFIG");
          const { USER_MAXIMUM_IN_DAY, SYSTEM_MAXIMUM_IN_DAY } = JSON.parse(data.value);
          store.dispatch({
            type: GET_WITHDRAW_CONFIG,
            data: { USER_MAXIMUM_IN_DAY, SYSTEM_MAXIMUM_IN_DAY },
          });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_WITHDRAW_CONFIG_ERROR,
            error: res.error,
          })
        );
  }

  static async setConfig(payload: any) {
    return RequestMainService.post(`/admin/server-config`, payload);
  }

  static async getServerConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/get-maintenance-server`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_CONFIG, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_CONFIG_ERROR,
            error: res.error,
          })
        );
  }

  static async swithMaintenanceServer(enable: any) {
    return RequestMainService.post(`/admin/switch-maintenance-server`, enable);
  }

  static async getServerConfigRolePermission(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/role-permission`)
        .then((res) => {
          store.dispatch({
            type: GET_ROLE_PERMISSION_CONFIG,
            data: res.result,
          });
        })
        .catch((err) => {
          store.dispatch({
            type: GET_ROLE_PERMISSION_CONFIG_ERROR,
            error: err.error,
          });
        });
  }

  static async updateRolePermissionServer(id: number, status: boolean) {
    return RequestMainService.put(`/admin/role-permission/${id}/${status}`);
  }

  static async ChangePassword(payload: any) {
    return RequestMainService.put(`/admin/change-password`, payload);
  }

  static async getActivitiesSetting(payload: any) {
    return RequestMainService.get(`/admin/user-activity`, payload).then((res) => ({
      count: res.result.total,
      data: res.result.data,
    }));
  }

  static getListActionActivity() {
    return [
      { label: "VIEW", value: "VIEW" },
      { label: "EDIT", value: "EDIT" },
      { label: "CREATE", value: "CREATE" },
      { label: "DELETE", value: "DELETE" },
      { label: "APPROVE", value: "APPROVE" },
      { label: "REJECT", value: "REJECT" },
      { label: "LOGIN", value: "LOGIN" },
      { label: "LOGOUT", value: "LOGOUT" },
    ];
  }

  static async getListRankConfigWithdraw(store: Store) {
    return RequestMainService.get("/admin/rank-config")
      .then(({ result }) => {
        store.dispatch({
          type: GET_RANK_CONFIG_WITHDRAW,
          data: result?.data,
        });
      })
      .catch((res) =>
        store.dispatch({
          type: GET_RANK_CONFIG_WITHDRAW_ERROR,
          error: res.error,
        })
      );
  }

  static async updateListRankConfigWithdraw(data: any) {
    return RequestMainService.post("/admin/update-rank-config", data);
  }

  static async getListNews() {
    return RequestMainService.get("/admin/news").then((res) => ({
      count: res.result.total,
      data: res.result.data.sort((a: any, b: any) => a.poolStreakId - b.poolStreakId),
    }));
  }

  static async editNews(id: number, payload: any) {
    return RequestMainService.put(`/admin/news/${id}`, payload);
  }

  static async createNews(payload: any) {
    return RequestMainService.post("/admin/news", payload);
  }

  static async getJobServiceConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/job/maintenance`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_JOB_SERVICE, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_JOB_SERVICE_ERROR,
            error: res.error,
          })
        );
  }

  static async swithMaintenanceJobService(flag: any) {
    return RequestMainService.put(`/job/maintenance/${flag}`);
  }
}
