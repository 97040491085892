/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function FeatureSectionTwo() {
  return (
    <section className="feature-area-s1 s-padding-t">
      <div className="container">
        <div className="s-title-wrap">
          <span className="s-sub-title">Services We Deliver</span>
          <h2 className="s-title">Các sản phẩm & dịch vụ dành cho bạn</h2>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div
              className="feature-s2 wow animate__animated animate__fadeInLeft"
              data-wow-duration="1.5s"
              data-wow-delay=".5s"
            >
              <div className="icon">
                <img
                  src={"/assets/images/feature-icons/cloud-icon.svg"}
                  alt=""
                />
              </div>
              <div className="content">
                <h4>
                  <a href="#">Tên miền / Server</a>
                </h4>
                <p>
                  Hỗ trợ đăng ký tên miền, hosting & vận hành server hàng năm
                  với chi phí cạnh tranh
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 floating">
            <div
              className="feature-s2 wow animate__animated animate__fadeInLeft"
              data-wow-duration="1.5s"
              data-wow-delay=".5s"
            >
              <div className="icon">
                <img
                  src={"/assets/images/feature-icons/cyber-security.png"}
                  alt=""
                />
              </div>
              <div className="content">
                <h4>
                  <a href="#">Email theo tên miền</a>
                </h4>
                <p>
                  Tạo nhiều địa chỉ email theo tên miền công ty, phân quyền
                  người dùng và nhiều tiện ích bổ sung
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div
              className="feature-s2 wow animate__animated animate__fadeInLeft"
              data-wow-duration="1.5s"
              data-wow-delay="1.5s"
            >
              <div className="icon">
                <img
                  src={"/assets/images/feature-icons/web-development.png"}
                  alt=""
                />
              </div>
              <div className="content">
                <h4>
                  <a href="#">Thiết kế website</a>
                </h4>
                <p>
                  Lập trình & thiết kế website theo yêu cầu. Đáp ứng sẵn có tính
                  năng từ cơ bản đến nâng cao
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div
              className="feature-s2 wow animate__animated animate__fadeInRight"
              data-wow-duration="1.5s"
              data-wow-delay="2s"
            >
              <div className="icon">
                <img
                  src={"/assets/images/feature-icons/cloud-icon.svg"}
                  alt=""
                />
              </div>
              <div className="content">
                <h4>
                  <a href="#">Mobile App</a>
                </h4>
                <p>
                  Sử dụng các ngôn ngữ lập trình phổ biến hiện nay để phát hiện
                  ứng dụng mobile đa nền tảng, tiết kiệm chi phí
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 d-lg-none">
            <div
              className="feature-s2 wow animate__animated animate__fadeInRight"
              data-wow-duration="1.5s"
              data-wow-delay="2.5s"
            >
              <div className="icon">
                <img
                  src={"/assets/images/feature-icons/pencil-icon.svg"}
                  alt=""
                />
              </div>
              <div className="content">
                <h4>
                  <a href="#">SEO Marketing</a>
                </h4>
                <p>
                  Hỗ trợ chạy marketing trên các nên tảng phổ biến hiện nay như:
                  Google, Facebook, Tiktok,...
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 d-lg-none">
            <div
              className="feature-s2 wow animate__animated animate__fadeInRight"
              data-wow-duration="1.5s"
              data-wow-delay="3s"
            >
              <div className="icon">
                <img src={"/assets/images/feature-icons/analytix.png"} alt="" />
              </div>
              <div className="content">
                <h4>
                  <a href="#">Hệ thống đầu tư</a>
                </h4>
                <p>
                  Xây dựng hệ thống ứng dụng web cho phép user đầu tư, giải pháp
                  nạp & rút và theo dõi dòng tiền liên tục
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSectionTwo;
