import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import { Icon } from "../../../components";

export interface ISwatTeamWraperProps extends RouteComponentProps {}

export const withSwatTeamWraper =
  (Component: FC<ISwatTeamWraperProps>) => (props: ISwatTeamWraperProps) => {
    return (
      <div className="withSwatTeamWraper">
        <div className="TabLinks">
          <NavLink
            to={Routes.GitlabVariables.path}
            exact={true}
            isActive={() => {
              return props?.location?.pathname?.includes("/admin/swat-team/gitlab-variables");
            }}
          >
            Gitlab Variables
          </NavLink>

          <NavLink to={Routes.News.path}>
            Airdrop
          </NavLink>
        </div>

        <Component {...props} />
      </div>
    );
  };
